import { Store } from "react-notifications-component";

const insert = "top";
const container = "top-right";

export const successNotification = (titleText, messageText) => {
  Store.addNotification({
    title: `${titleText}`,
    message: `${messageText}`,
    type: "success",
    insert: insert,
    container: container,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const warnNotification = (titleText, messageText) => {
  Store.addNotification({
    title: `${titleText}`,
    message: `${messageText}`,
    type: "warning",
    insert: insert,
    container: container,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const errorNotification = (titleText, messageText) => {
  Store.addNotification({
    title: `${titleText}`,
    message: `${messageText}`,
    type: "danger",
    insert: insert,
    container: container,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
